import React, { SVGProps } from 'react';

export default function TwilioLogo(props: SVGProps<SVGSVGElement>) {
  return (
<svg width="101" height="30" viewBox="0 0 101 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M17.928 18.7109H12.0625C8.86836 18.7109 6.26953 16.0885 6.26953 12.8653V9.71028C6.27203 6.48964 8.86836 3.86719 12.0625 3.86719H17.928C21.1221 3.86719 23.7209 6.48964 23.7209 9.71028V12.8628C23.7209 16.0885 21.1221 18.7109 17.928 18.7109ZM12.0625 7.89299C11.067 7.89299 10.2591 8.70825 10.2591 9.71281V12.8653C10.2591 13.8699 11.067 14.6851 12.0625 14.6851H17.928C18.9235 14.6851 19.7314 13.8699 19.7314 12.8653V9.71028C19.7314 8.70573 18.9235 7.89047 17.928 7.89047H12.0625V7.89299Z" fill="#3B4B5B"/>
<path d="M11.1777 23.5633C11.1777 22.1431 10.0776 20.9941 8.72363 20.9941C7.36708 20.9941 6.26953 22.1458 6.26953 23.5633C6.26953 24.9834 7.36965 26.1324 8.72363 26.1324C10.0802 26.1324 11.1777 24.9834 11.1777 23.5633Z" fill="#C93B59"/>
<path d="M40.2877 9.25684C40.5517 9.25684 40.8048 9.31185 41.0468 9.42187C41.2889 9.52088 41.4979 9.65841 41.6739 9.83444C41.85 10.0105 41.9875 10.2195 42.0865 10.4616C42.1965 10.7036 42.2515 10.9567 42.2515 11.2207V15.5445C42.2515 15.8196 42.1965 16.0781 42.0865 16.3202C41.9875 16.5512 41.85 16.7547 41.6739 16.9308C41.4979 17.1068 41.2889 17.2498 41.0468 17.3598C40.8048 17.4589 40.5517 17.5084 40.2877 17.5084H35.9639C35.6668 17.5084 35.4083 17.4534 35.1882 17.3433V20.743H34V11.2207C34 10.9567 34.0495 10.7036 34.1485 10.4616C34.2585 10.2195 34.4016 10.0105 34.5776 9.83444C34.7536 9.65841 34.9572 9.52088 35.1882 9.42187C35.4303 9.31185 35.6888 9.25684 35.9639 9.25684H40.2877ZM41.0633 11.2207C41.0633 11.0007 40.9863 10.8191 40.8323 10.6761C40.6892 10.5221 40.5077 10.4451 40.2877 10.4451H35.9639C35.7438 10.4451 35.5568 10.5221 35.4028 10.6761C35.2597 10.8191 35.1882 11.0007 35.1882 11.2207V15.6435C35.2102 15.8306 35.2927 15.9901 35.4358 16.1221C35.5898 16.2541 35.7658 16.3202 35.9639 16.3202H40.2877C40.5077 16.3202 40.6892 16.2486 40.8323 16.1056C40.9863 15.9516 41.0633 15.7646 41.0633 15.5445V11.2207Z" fill="#3B4B5B"/>
<path d="M45.7486 9.25684H50.0724C50.3365 9.25684 50.5895 9.31185 50.8316 9.42187C51.0736 9.52088 51.2826 9.65841 51.4587 9.83444C51.6347 10.0105 51.7722 10.2195 51.8712 10.4616C51.9813 10.6926 52.0363 10.9457 52.0363 11.2207H50.8481C50.8481 11.0007 50.771 10.8191 50.617 10.6761C50.474 10.5221 50.2925 10.4451 50.0724 10.4451H45.7486C45.5286 10.4451 45.3415 10.5221 45.1875 10.6761C45.0445 10.8191 44.973 11.0007 44.973 11.2207V17.5084H43.7847V11.2207C43.7847 10.9457 43.8342 10.6926 43.9333 10.4616C44.0433 10.2195 44.1863 10.0105 44.3623 9.83444C44.5384 9.65841 44.7419 9.52088 44.973 9.42187C45.215 9.31185 45.4736 9.25684 45.7486 9.25684Z" fill="#3B4B5B"/>
<path d="M59.8571 9.25684C60.1212 9.25684 60.3742 9.31185 60.6163 9.42187C60.8583 9.52088 61.0674 9.65841 61.2434 9.83444C61.4194 10.0105 61.557 10.2195 61.656 10.4616C61.766 10.6926 61.821 10.9401 61.821 11.2042V12.1119C61.81 12.3759 61.7495 12.6235 61.6395 12.8545C61.5295 13.0745 61.3864 13.2671 61.2104 13.4321C61.0344 13.5971 60.8308 13.7292 60.5998 13.8282C60.3687 13.9272 60.1212 13.9767 59.8571 13.9767H54.7577V15.5445C54.7577 15.7646 54.8292 15.9516 54.9722 16.1056C55.1263 16.2486 55.3133 16.3202 55.5333 16.3202H59.8571C60.0772 16.3202 60.2587 16.2486 60.4018 16.1056C60.5558 15.9516 60.6328 15.7646 60.6328 15.5445H61.821C61.821 15.8196 61.766 16.0781 61.656 16.3202C61.557 16.5512 61.4194 16.7547 61.2434 16.9308C61.0674 17.1068 60.8583 17.2498 60.6163 17.3598C60.3742 17.4589 60.1212 17.5084 59.8571 17.5084H55.5333C55.2583 17.5084 54.9997 17.4589 54.7577 17.3598C54.5267 17.2498 54.3231 17.1068 54.1471 16.9308C53.971 16.7547 53.828 16.5512 53.718 16.3202C53.619 16.0781 53.5695 15.8196 53.5695 15.5445V11.2207C53.5695 10.9567 53.619 10.7036 53.718 10.4616C53.828 10.2195 53.971 10.0105 54.1471 9.83444C54.3231 9.65841 54.5267 9.52088 54.7577 9.42187C54.9997 9.31185 55.2583 9.25684 55.5333 9.25684H59.8571ZM60.6328 11.2207C60.6328 11.0007 60.5558 10.8191 60.4018 10.6761C60.2587 10.5221 60.0772 10.4451 59.8571 10.4451H55.5333C55.3133 10.4451 55.1263 10.5221 54.9722 10.6761C54.8292 10.8191 54.7577 11.0007 54.7577 11.2207V12.7885H59.8571C60.0662 12.7885 60.2422 12.717 60.3852 12.574C60.5393 12.4309 60.6218 12.2549 60.6328 12.0459V11.2207Z" fill="#3B4B5B"/>
<path d="M69.6419 9.25684C69.9059 9.25684 70.159 9.31185 70.401 9.42187C70.6431 9.52088 70.8521 9.65841 71.0281 9.83444C71.2042 10.0105 71.3417 10.2195 71.4407 10.4616C71.5507 10.7036 71.6058 10.9567 71.6058 11.2207V15.5445C71.6058 15.8196 71.5507 16.0781 71.4407 16.3202C71.3417 16.5512 71.2042 16.7547 71.0281 16.9308C70.8521 17.1068 70.6431 17.2498 70.401 17.3598C70.159 17.4589 69.9059 17.5084 69.6419 17.5084H65.3181C65.021 17.5084 64.7625 17.4534 64.5424 17.3433V20.743H63.3542V11.2207C63.3542 10.9567 63.4037 10.7036 63.5027 10.4616C63.6128 10.2195 63.7558 10.0105 63.9318 9.83444C64.1079 9.65841 64.3114 9.52088 64.5424 9.42187C64.7845 9.31185 65.043 9.25684 65.3181 9.25684H69.6419ZM70.4175 11.2207C70.4175 11.0007 70.3405 10.8191 70.1865 10.6761C70.0435 10.5221 69.8619 10.4451 69.6419 10.4451H65.3181C65.098 10.4451 64.911 10.5221 64.757 10.6761C64.6139 10.8191 64.5424 11.0007 64.5424 11.2207V15.6435C64.5644 15.8306 64.647 15.9901 64.79 16.1221C64.944 16.2541 65.12 16.3202 65.3181 16.3202H69.6419C69.8619 16.3202 70.0435 16.2486 70.1865 16.1056C70.3405 15.9516 70.4175 15.7646 70.4175 15.5445V11.2207Z" fill="#3B4B5B"/>
<path d="M81.3575 15.6435C81.3795 15.8416 81.462 16.0121 81.605 16.1551C81.7481 16.2871 81.9186 16.3587 82.1166 16.3697V17.5414C81.8526 17.5414 81.605 17.4919 81.374 17.3929C81.1429 17.2938 80.9339 17.1563 80.7469 16.9803C80.5708 17.1453 80.3673 17.2773 80.1363 17.3764C79.9052 17.4644 79.6632 17.5084 79.4101 17.5084H75.0863C74.8223 17.5084 74.5692 17.4589 74.3272 17.3598C74.0961 17.2498 73.8926 17.1068 73.7166 16.9308C73.5405 16.7547 73.3975 16.5512 73.2875 16.3202C73.1885 16.0781 73.1389 15.8251 73.1389 15.561V11.2537C73.1389 10.9897 73.1885 10.7366 73.2875 10.4946C73.3975 10.2525 73.5405 10.0435 73.7166 9.86745C73.8926 9.69142 74.0961 9.55389 74.3272 9.45487C74.5692 9.34485 74.8223 9.28984 75.0863 9.28984H79.4101C79.6742 9.28984 79.9217 9.34485 80.1528 9.45487C80.3948 9.55389 80.6038 9.69142 80.7799 9.86745C80.9559 10.0435 81.0934 10.2525 81.1925 10.4946C81.3025 10.7366 81.3575 10.9897 81.3575 11.2537V15.6435ZM80.1693 11.2537C80.1693 11.0337 80.0922 10.8521 79.9382 10.7091C79.7952 10.5551 79.6192 10.4781 79.4101 10.4781H75.0863C74.8773 10.4781 74.6957 10.5551 74.5417 10.7091C74.3987 10.8521 74.3272 11.0337 74.3272 11.2537V15.561C74.3272 15.7811 74.3987 15.9681 74.5417 16.1221C74.6957 16.2651 74.8773 16.3367 75.0863 16.3367H79.4101C79.6082 16.3367 79.7787 16.2706 79.9217 16.1386C80.0647 15.9956 80.1473 15.8251 80.1693 15.627V11.2537Z" fill="#3B4B5B"/>
<path d="M84.8553 9.25684H89.1791C89.4432 9.25684 89.6962 9.31185 89.9383 9.42187C90.1803 9.52088 90.3894 9.65841 90.5654 9.83444C90.7414 10.0105 90.8789 10.2195 90.978 10.4616C91.088 10.6926 91.143 10.9457 91.143 11.2207H89.9548C89.9548 11.0007 89.8778 10.8191 89.7237 10.6761C89.5807 10.5221 89.3992 10.4451 89.1791 10.4451H84.8553C84.6353 10.4451 84.4482 10.5221 84.2942 10.6761C84.1512 10.8191 84.0797 11.0007 84.0797 11.2207V17.5084H82.8915V11.2207C82.8915 10.9457 82.941 10.6926 83.04 10.4616C83.15 10.2195 83.293 10.0105 83.4691 9.83444C83.6451 9.65841 83.8486 9.52088 84.0797 9.42187C84.3217 9.31185 84.5803 9.25684 84.8553 9.25684Z" fill="#3B4B5B"/>
<path d="M98.9639 9.25684C99.2279 9.25684 99.481 9.31185 99.723 9.42187C99.9651 9.52088 100.174 9.65841 100.35 9.83444C100.526 10.0105 100.664 10.2195 100.763 10.4616C100.873 10.7036 100.928 10.9567 100.928 11.2207V15.5445C100.928 15.8196 100.873 16.0781 100.763 16.3202C100.664 16.5512 100.526 16.7547 100.35 16.9308C100.174 17.1068 99.9651 17.2498 99.723 17.3598C99.481 17.4589 99.2279 17.5084 98.9639 17.5084H94.6401C94.365 17.5084 94.1065 17.4589 93.8644 17.3598C93.6334 17.2498 93.4298 17.1068 93.2538 16.9308C93.0778 16.7547 92.9347 16.5512 92.8247 16.3202C92.7257 16.0781 92.6762 15.8196 92.6762 15.5445V11.2207C92.6762 10.9567 92.7257 10.7036 92.8247 10.4616C92.9347 10.2195 93.0778 10.0105 93.2538 9.83444C93.4298 9.65841 93.6334 9.52088 93.8644 9.42187C94.1065 9.31185 94.365 9.25684 94.6401 9.25684H98.9639ZM99.7395 11.2207C99.7395 11.0007 99.6625 10.8191 99.5085 10.6761C99.3654 10.5221 99.1839 10.4451 98.9639 10.4451H94.6401C94.42 10.4451 94.233 10.5221 94.079 10.6761C93.9359 10.8191 93.8644 11.0007 93.8644 11.2207V15.5445C93.8644 15.7646 93.9359 15.9516 94.079 16.1056C94.233 16.2486 94.42 16.3202 94.6401 16.3202H98.9639C99.1839 16.3202 99.3654 16.2486 99.5085 16.1056C99.6625 15.9516 99.7395 15.7646 99.7395 15.5445V11.2207Z" fill="#3B4B5B"/>
</svg>




  );
}
